<template>
  <button @click="updateStep" :class="classes">
    <span class="nav-item__number" v-if="$slots.default">
      <slot />
    </span>
    {{ text }}
  </button>
</template>

<script>
import { stepsNames, steps } from '@/utils/deepSettings'

export default {
  props: {
    step: {
      type: String
    },
    currentStep: {
      type: String,
      required: true
    }
  },
  methods: {
    updateStep() {
      this.$emit('updateStep', this.step)
    }
  },
  name: 'AsideNavItem',
  computed: {
    classes() {
      return {
        'nav-item': true,
        'nav-item--active': this.currentStep === this.step,
        'nav-item--passed': this.isStepPassed
      }
    },
    text() {
      return stepsNames[this.step]
    },
    isStepPassed() {
      const simplifiedSteps = Object.values(steps)
      const currentStepIndex = simplifiedSteps.findIndex(step => step === this.currentStep)
      const thisStepIndex = simplifiedSteps.findIndex(step => step === this.step)
      return thisStepIndex < currentStepIndex
    }
  }
}
</script>

<style scoped lang="sass">
.nav-item
  @extend .clear-btn
  font-family: $main-font
  font-style: normal
  +media((font-weight: (768: bold)))
  +media((font-size: (0: rem(12), 768: rem(14))))
  // +media((max-width: (0: rem(90), 360: unset)))
  +media((width: (0: calc(100% / 3), 768: auto)))
  line-height: rem(16)
  color: $black_C
  +media((text-align: (0: center, 1200: left)))
  +media((padding: (0: rem(16) rem(10), 768: rem(18), 1200: rem(18) rem(50))))
  min-height: rem(56)
  transition: .5s
  +media((background: (768: rgba($white, .5))))
  +media((box-shadow: (768: inset rem(4) 0 0 $cool_A)))
  display: flex
  align-items: center
  +media((justify-content: (0: center, 768: flex-start)))
  position: relative
  &:before
    content: ""
    pointer-events: none
    position: absolute
    left: 50%
    bottom: 0
    width: rem(30)
    height: rem(3)
    +media((display: (768: none)))
    background: $cool_A
    transform: translateX(-50%)
  &:not(:last-child)
    &:after
      content: ""
      width: rem(25)
      background-image: url("~@/assets/img/icons/modal--path-chevron.svg")
      background-repeat: no-repeat
      background-position: center center
      position: absolute
      right: 0
      height: 100%
      transform: translateX(100%)
      pointer-events: none
      +media((display: (0: none, 360: block, 768: none)))
  &__number
    transition: .5s
    min-width: rem(30)
    text-align: left
    +media((display: (0: none, 768: block)))
  &--active
    +media((box-shadow: (768: inset rem(4) 0 0 $red)))
    color: $black
    &:before
      background: $red
    .nav-item
      &__number
        color: $red
  &--passed
    +media((box-shadow: (768: inset rem(4) 0 0 $green)))
    &:before
      background: $green
</style>
