<template>
  <section class="deep-settings">
    <Aside @updateStep="updateStep" :current-step="currentStep" @goToDashBoard="goToDashBoard" />
    <div class="deep-settings__body">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        color="#E30713"
        :height="145"
        :width="145"
        :opacity="0.7"
        backgroundColor="#eeeeee"
        :z-index="5"
        :is-full-page="true"
        v-if="isLoading"
      ></loading>

      <section class="deep-settings__body-steps">
        <modal :is-modal-open="isModalOpen" :is-show-close="false" @close="closeModal">
          <template #body>
            <save-modal-content
              :cipher="applicationData.cipher"
              :url-hash="$route.params.hash"
              :is-loading="modalIsLoading"
              @close="closeModal"
              @savePdf="savePdf"
              @saveXls="saveXls"
              @goToDashBoard="goToDashBoard"
            />
          </template>
        </modal>
        <component
          :is="currentStepComponent"
          :current-step="currentStep"
          :is-loading="isLoading"
          @next="next"
          @prev="prev"
        >
          <template>
            <app-button color="grey" size="large" @click="saveMethod(saveMethods.draft)">
              Сохранить черновик
              <template #icon>
                <inline-svg :src="require('@/assets/img/icons/deep-settings/draft.svg')" />
              </template>
              <loading
                :active.sync="saveButtonsIsLoading"
                :can-cancel="false"
                color="#000"
                :height="30"
                :width="30"
                :opacity="1"
                backgroundColor="transparent"
                :z-index="3"
                :is-full-page="false"
              ></loading>
            </app-button>
            <app-button color="red" size="large" @click="saveMethod">
              Сохранить расчет
              <template #icon>
                <img src="@/assets/img/icons/deep-settings/cloud.svg" alt="" />
              </template>
              <loading
                :active.sync="saveButtonsIsLoading"
                :can-cancel="false"
                color="#fff"
                :height="30"
                :width="30"
                :opacity="1"
                backgroundColor="transparent"
                :z-index="3"
                :is-full-page="false"
              ></loading>
            </app-button>
          </template>
        </component>
      </section>
    </div>
  </section>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import {
  checkIsCalculationBelongsToUser,
  getExtendedPdf,
  getExtendedXls,
  updateCalculation
} from '@/api/moderator'
import { parametersNames } from '@/utils/calculationPriceStep'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { bodyBgMixin } from '@/mixins'
import { handleError, steps } from '@/utils/deepSettings'
import Aside from '@/components/smart/DeepSettings/Aside'
import AppButton from '@/components/elements/AppButton'
import LinkView from '@/components/smart/DeepSettings/LinkView'
import { sumElements } from '@/utils/customFunctions'
import SaveModalContent from '@/components/smart/DeepSettings/SaveModalContent'
// import {pages} from "@/utils";
import { saveAs } from 'file-saver'
const saveMethods = {
  draft: 'draft',
  full: 'full'
}

const stepsNumbers = {
  [steps.commonData]: 1,
  [steps.calculationAccuracy]: 2,
  [steps.calculationPrice]: 3
}

const stepsByNumber = Object.fromEntries(
  Object.entries(stepsNumbers).map(item => {
    const [stepName, stepNumber] = item
    return [stepNumber, stepName]
  })
)

const stepsComponents = {
  [steps.commonData]: () => import('@/components/smart/DeepSettings/CommonDataStep'),
  [steps.calculationAccuracy]: () =>
    import('@/components/smart/DeepSettings/CalculationAccuracyStep'),
  [steps.calculationPrice]: () => import('@/components/smart/DeepSettings/CalculationPriceStep')
}

export default {
  name: 'DeepSettings',
  mixins: [bodyBgMixin],
  components: {
    InlineSvg,
    SaveModalContent,
    LinkView,
    Modal: () => import('@/components/elements/Modals/Modal'),
    AppButton,
    Aside
  },
  data: () => ({
    currentStep: steps.commonData,
    saveButtonsIsLoading: false,
    isModalOpen: false,
    urlHash: null,
    modalIsLoading: false
  }),
  methods: {
    ...mapActions({
      getAllDataForDeepSettingsPage: 'getAllDataForDeepSettingsPage'
    }),
    ...mapActions('moderator', {
      updateUserSettings: 'updateUserSettings'
    }),
    ...mapMutations('moderator', {
      SET_CURRENT_CALCULATION_HASH: 'SET_CURRENT_CALCULATION_HASH'
      // RESTORE_DEFAULT_DEEP_SETTINGS: 'RESTORE_DEFAULT_DEEP_SETTINGS'
    }),
    savePdf() {
      this.modalIsLoading = true
      getExtendedPdf({ ...this.pdfOrXlsPayload })
        .then(response => {
          this.modalIsLoading = false
          const file = new Blob([response.data], { type: 'application/pdf' })
          const fileURL = URL.createObjectURL(file)
          window.open(fileURL)
        })
        .catch(e => {
          handleError(this.$notify, e)
          this.modalIsLoading = false
        })
    },
    saveXls() {
      this.modalIsLoading = true
      getExtendedXls({ ...this.pdfOrXlsPayload })
        .then(response => {
          this.modalIsLoading = false
          const file = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          })
          const newFileName = `Расчет ${this.getAllDeepSettings.applicationData.cipher}.xlsx`
          saveAs(file, newFileName)
        })
        .catch(e => {
          handleError(this.$notify, e)
          this.modalIsLoading = false
        })
    },
    goToDashBoard() {
      this.$router.push(`/${this.$i18n.locale}/dashboard`)
    },
    sumSquare(arr) {
      return sumElements(arr, 'square')
    },
    updateStep(to, from) {
      // this.currentStep = step

      if (
        stepsNumbers[to] < stepsNumbers[from] ||
        (stepsNumbers[to] === stepsNumbers[from] + 1 && !this.getIsFormFalid)
      ) {
        this.currentStep = to
      }
    },
    next() {
      const currentStepNumber = stepsNumbers[this.currentStep]
      this.currentStep = stepsByNumber[currentStepNumber + 1]
      // this.updateStep(stepsNumbers)
    },
    prev() {
      const currentStepNumber = stepsNumbers[this.currentStep]
      this.updateStep(stepsByNumber[currentStepNumber - 1], this.currentStep)
      // this.updateStep(stepsNumbers)
    },
    closeModal() {
      this.urlHash = null
      this.isModalOpen = false
    },
    saveMethod(method) {
      // начало проверки на наличие заполненого пукта в СБЕ
      let obj = {}
      for (let key in this.calculationPrice) {
        if (
          this.calculationPrice[key].hasOwnProperty('sbeList') &&
          this.calculationPrice[key].isEnabled
        ) {
          for (let deepKey in this.calculationPrice[key].sbeList) {
            if (this.calculationPrice[key].sbeList[deepKey].isEnabled) {
              obj[key] = this.calculationPrice[key].sbeList[deepKey].isEnabled
              break
            } else {
              obj[key] = false
            }
          }
        }
      }
      if (Object.values(obj).filter(item => item).length === Object.keys(obj).length) {
        try {
          this.saveButtonsIsLoading = true
          const isSaveDraft = method === saveMethods.draft
          const { $i18n, userId, sectors, result, getAllDeepSettings, $route } = this
          const { locale: lang } = $i18n
          const { hash } = $route.params
          this.updateUserSettings()
          updateCalculation({
            lang,
            userId,
            sectors,
            result,
            deepSettings: { ...getAllDeepSettings },
            action: 'update',
            hash,
            isDraft: isSaveDraft
          }).then(() => {
            this.saveButtonsIsLoading = false
            this.isModalOpen = !isSaveDraft
            if (isSaveDraft) {
              this.saveButtonsIsLoading = false
              this.$notify({
                group: 'apiNotifications',
                type: 'success',
                title: 'Черновик успешно сохранен'
              })
            }
          })
        } catch (error) {
          this.saveButtonsIsLoading = false
          this.$notify({
            group: 'apiNotifications',
            type: 'error',
            title: 'При сохранении возникла ошибка',
            text: error
          })
        }
      } else {
        for (let key in obj) {
          if (!obj[key]) {
            this.$notify({
              group: 'apiNotifications',
              type: 'error',
              title: 'Не выбран СБЕ',
              text: `выберите СБЕ для ${parametersNames[key]}`
            })
          }
        }
      }
    }
  },
  async mounted() {
    this.$root.onBodyScroll(false)
    const { hash } = this.$route.params
    const { $i18n } = this
    const { locale: lang } = $i18n
    if (hash) {
      const { userId } = this
      try {
        const { data: isUserHasAccess } = await checkIsCalculationBelongsToUser(userId, hash)
        if (isUserHasAccess) {
          this.SET_CURRENT_CALCULATION_HASH(hash)
          this.getAllDataForDeepSettingsPage({
            lang,
            hash,
            page: 'deepSettings',
            userId
          })
        } else {
          await this.$router.push(`/${lang}/dashboard`)
        }
      } catch (e) {
        await this.$router.push(`/${lang}/dashboard`)
      }
    } else {
      await this.$router.push(`/${lang}/`)
    }
  },
  beforeDestroy() {
    // this.RESTORE_DEFAULT_DEEP_SETTINGS()
  },
  watch: {
    currentStep() {
      window.scrollTo(0, 0)
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.pages.deepSettings.isLoading,
      sectors: state => state.sectors,
      result: state => state.result
    }),
    ...mapState('moderator', {
      userId: state => state.userId,
      calculationTypes: state => state.calculationTypes,
      accuracyStepData: state => state.accuracyStepData
    }),
    ...mapGetters('moderator', {
      getAllDeepSettings: 'getAllDeepSettings',
      getIsFormFalid: 'getIsFormFalid'
    }),
    sectorsCount() {
      return this.sectors.length
    },
    junctionsCount() {
      return this.sectors.reduce(
        (accumulator, currentValue) => accumulator + currentValue.junctions.length,
        0
      )
    },
    ...mapState('moderator', {
      calculationAccuracy: state => state.calculationAccuracy,
      applicationData: state => state.applicationData,
      objectData: state => state.objectData,
      customerClientData: state => state.customerClientData,
      calculationPrice: state => state.calculationPrice,
      userId: state => state.userId,
      regions: state => state.regions,
      calculationTypes: state => state.calculationTypes,
      stages: state => state.buildingStages
    }),
    ...mapState({
      sectors: state => state.sectors,
      result: state => state.result
    }),
    currentStepComponent() {
      return stepsComponents[this.currentStep]
    },
    steps: () => steps,
    saveMethods: () => saveMethods,
    pdfOrXlsPayload() {
      const {
        $i18n,
        regions,
        stages,
        calculationTypes,
        getAllDeepSettings,
        accuracyStepData,
        sectors,
        result
      } = this
      const { locale: lang } = $i18n

      const { hash } = this.$route.params
      return {
        lang,
        deepSettings: { ...getAllDeepSettings },
        regions,
        stages,
        calculationTypes,
        accuracyStepData,
        sectors,
        result,
        hash,
        domenName: `${location.origin}/${this.$i18n.locale}`
      }
    }
  }
}
</script>

<style scoped lang="sass">
.deep-settings
  display: grid
  min-height: calc(100vh - 84px)
  // grid-template-columns: minmax(rem(350), 7fr) 16fr

  +media((grid-template-columns: (0: 1fr, 768: rem(250) 1fr, 1200: rem(350) 1fr)))
  &__body
    padding: rem(48) 0 rem(50)
    display: grid
    grid-template-columns: 2fr 27fr 2fr
    &-steps
      grid-column-start: 2
</style>
