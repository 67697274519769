<template>
  <div class="save-modal__content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      backgroundColor="#eeeeee"
      :z-index="5"
      :is-full-page="true"
    ></loading>
    <div class="save-modal__content-head">
      <div class="title">
        <span class="title__bold">
          <span class="title__bold"> Расчет {{ cipher }} </span> успешно сохранен
        </span>
      </div>
      <button class="close" @click="close">
        <img src="@/assets/img/icons/deep-settings/close.svg" alt="❌" />
      </button>
    </div>
    <div class="save-modal__content-body">
      <div class="save-modal__body">
        <link-view :url-hash="urlHash" />
      </div>
    </div>
    <div class="save-modal__content-footer">
      <app-button color="black" size="large" @click="goToDashBoard">
        <template #icon>
          <img src="@/assets/img/icons/deep-settings/nav-prev.svg" alt="←" />
        </template>
        Перейти в кабинет
      </app-button>
      <div class="download">
        <app-button color="grey" size="large" @click="savePdf">
          <template #icon>
            <inline-svg :src="require('@/assets/img/icons/deep-settings/download.svg')" />
          </template>
          Скачать расчет PDF
        </app-button>
        <app-button color="grey" size="large" @click="saveXls">
          <template #icon>
            <inline-svg :src="require('@/assets/img/icons/deep-settings/download.svg')" />
          </template>
          Скачать расчет XLS
        </app-button>
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import LinkView from '@/components/smart/DeepSettings/LinkView'
import AppButton from '@/components/elements/AppButton'
export default {
  components: { AppButton, LinkView, InlineSvg },
  props: {
    cipher: {
      type: String,
      required: true
    },
    urlHash: {
      type: String,
      required: true
    },
    isLoading: Boolean
  },
  name: 'SaveModalContent',
  methods: {
    close() {
      this.$emit('close')
    },
    savePdf() {
      this.$emit('savePdf')
    },
    saveXls() {
      this.$emit('saveXls')
    },
    goToDashBoard() {
      this.$emit('goToDashBoard')
    }
  }
}
</script>

<style scoped lang="sass">
.save-modal
  &__content
    padding: rem(67) rem(80) rem(64)
    display: grid
    gap: rem(64)
    &-head
      display: flex
      align-items: center
      justify-content: space-between
      gap: rem(20)
      .title
        color: $black
        @extend %H324B
        font-weight: normal
        &__bold
          font-weight: bold
      .close
        @extend %calculation-price-close
    &-footer
      display: flex
      align-items: center
      justify-content: space-between
      .download
        display: flex
        align-items: center
        gap: rem(4)
</style>
