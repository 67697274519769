<template>
  <aside class="deep-settings__aside">
    <div class="deep-settings__aside-title">Настройка стоимости <br />расчета</div>
    <!--    <app-button transparent class="app-button&#45;&#45;mobile" @click="goToDashboard">
      <template #icon>
        <img src="@/assets/img/icons/app-button&#45;&#45;arrow-back.svg" alt="←" />
      </template>
    </app-button>-->
    <div class="deep-settings__aside-nav">
      <aside-nav-item
        v-for="(step, i) in Object.values(steps)"
        :key="step"
        :step="step"
        @updateStep="updateStep"
        :current-step="currentStep"
      >
        {{ i + 1 }}
      </aside-nav-item>
    </div>
    <div class="deep-settings__aside-buttons">
      <app-button color="white" @click="goToResult">
        <template #icon>
          <inline-svg :src="require('@/assets/img/icons/deep-settings/to-result.svg')" />
        </template>
        Вернуться к расчету
      </app-button>
      <app-button color="white" @click="goToDashboard">
        <template #icon>
          <inline-svg :src="require('@/assets/img/icons/deep-settings/to-dashboard.svg')" />
        </template>
        Перейти в кабинет
      </app-button>
    </div>
  </aside>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import { steps } from '@/utils/deepSettings'
import AsideNavItem from '@/components/smart/DeepSettings/AsideNavItem'
import AppButton from '@/components/elements/AppButton'

export default {
  name: 'Aside',
  props: {
    currentStep: {
      type: String,
      required: true
    }
  },
  components: { AppButton, AsideNavItem, InlineSvg },
  methods: {
    updateStep(step) {
      this.$emit('updateStep', step, this.currentStep)
    },
    goToDashboard() {
      this.$emit('goToDashBoard')
    },
    goToResult() {
      const { lang, hash } = this.$route.params
      this.$router.push({ name: 'Result', params: { lang }, query: { object: hash } })
    }
  },
  computed: {
    steps: () => steps
  }
}
</script>

<style scoped lang="sass">
.deep-settings
  &__aside
    $gr: linear-gradient(0deg, $default 0%, $white 50%)
    +media((background: (0: $gr, 768: $default)))
    +media((padding: (768: rem(48) 0 rem(50))))
    display: flex
    +media((flex-direction: (768: column)))
    &-title
      @extend %H324B
      color: $black
      margin: 0 rem(50) rem(56)
      +media((display: (0: none, 768: block)))
    &-nav
      +media((display: (0: flex, 768: grid)))
      +media((width: (0: calc(100% - 40px), 768: 100%)))
      +media((column-gap: (360: rem(25), 768: 0)))
    &-buttons
      +media((margin: (768: rem(56) auto 0 rem(50))))
      display: grid
      gap: rem(4)
      .app-button
        margin-right: auto
    // .app-button
      +media((margin: (768: rem(56) auto 0 rem(50))))
      &--mobile
        +media((display: (768: none)))
      &--desktop
        +media((display: (0: none, 768: flex)))
</style>
